import React, { useEffect, useRef, useState } from 'react'
import { PropTypes } from 'prop-types'

import { toIndex } from '../../../utils'
import { Pager } from '../../'

const getInitialPageNr = (queryParams, totalProductCount) => {
  const count = Math.max(
    0,
    toIndex(queryParams.count) || process.env.PRODUCTS_PER_PAGE
  )
  const offset = Math.min(
    Math.max(0, toIndex(queryParams.offset)),
    Math.max(0, totalProductCount)
  )
  return Math.min(
    Math.max(0, Math.ceil(offset / count)),
    Math.ceil(totalProductCount / count) - 1
  )
}

const calculateTotalNumberOfPages = (queryParams, totalProductCount) => {
  const count = Math.max(
    1,
    toIndex(queryParams.count) || process.env.PRODUCTS_PER_PAGE
  )

  return Math.max(1, Math.ceil(totalProductCount / count))
}

const ListPagination = ({
  queryParams = {},
  submitForms,
  totalProductCount,
}) => {
  const [currentPageNr, setCurrentPageNr] = useState(
    getInitialPageNr(queryParams, totalProductCount)
  )
  const isMounted = useRef(false)

  const totalNumberOfPages = calculateTotalNumberOfPages(
    queryParams,
    totalProductCount
  )

  useEffect(() => {
    if (isMounted.current) {
      submitForms()
    } else {
      isMounted.current = true
    }
  }, [currentPageNr, submitForms])

  return (
    <Pager
      currentPageNr={currentPageNr}
      maxPageCount={totalNumberOfPages}
      setPage={setCurrentPageNr}
      className="mb-4"
    />
  )
}

ListPagination.propTypes = {
  queryParams: PropTypes.shape({
    count: PropTypes.string,
    offset: PropTypes.string,
  }),
  submitForms: PropTypes.func.isRequired,
  totalProductCount: PropTypes.number.isRequired,
}

export default ListPagination
