import React from 'react'
import { PropTypes } from 'prop-types'

import { Button, Icon } from '../..'
import { useTranslation } from '../../../utils'
import classNames from 'classnames'

const Pager = ({ currentPageNr, maxPageCount, setPage, className }) => {
  const { t } = useTranslation()

  const previousPage = () => {
    setPage(currentPageNr - 1)
  }

  const nextPage = () => {
    setPage(currentPageNr + 1)
  }

  const firstPage = () => {
    setPage(0)
  }

  const lastPage = () => {
    setPage(maxPageCount - 1)
  }

  const isFirstPage = currentPageNr < 1
  const isLastPage = currentPageNr + 1 >= maxPageCount

  const formClasses = classNames('list-pager', className)

  if (maxPageCount <= 1) return null

  return (
    <form className={formClasses}>
      <input type="hidden" name="pageNumber" value={currentPageNr} />

      <Button
        className="list-pager__btn"
        variant={null}
        disabled={isFirstPage}
        onClick={firstPage}
        title={t('PAGER_FIRST_PAGE')}
      >
        <Icon symbol="cf-prev"></Icon>
        <Icon symbol="cf-prev"></Icon>
      </Button>
      <Button
        className="list-pager__btn"
        variant={null}
        disabled={isFirstPage}
        onClick={previousPage}
        title={t('PAGER_PREV_PAGE')}
      >
        <Icon symbol="cf-prev"></Icon>
      </Button>

      <span className="list-pager__text">
        {t('PRODUCT_LIST_CURRENT_PAGE')?.(currentPageNr + 1, maxPageCount)}
      </span>

      <Button
        className="list-pager__btn"
        variant={null}
        disabled={isLastPage}
        onClick={nextPage}
        title={t('PAGER_NEXT_PAGE')}
      >
        <Icon className="cf-next"></Icon>
      </Button>
      <Button
        className="list-pager__btn"
        variant={null}
        disabled={isLastPage}
        onClick={lastPage}
        title={t('PAGER_LAST_PAGE')}
      >
        <Icon symbol="cf-next"></Icon>
        <Icon symbol="cf-next"></Icon>
      </Button>
    </form>
  )
}

Pager.propTypes = {
  currentPageNr: PropTypes.number.isRequired,
  maxPageCount: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export { default as pagerVariants } from './variants'
export default Pager
